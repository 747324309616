<template>
    <v-app dark>
        <v-toolbar fixed app clipped-left class="">
            <v-toolbar-title v-html="$context.title"    class="amber--text"/>
            <v-toolbar-title v-html="$context.subTitle" class="amber--text text--lighten-2"/>
        </v-toolbar>

        <app-content center/>

        <app-footer/>
    </v-app>
</template>

<script>
    export default {
        name : 'app',
    }
</script>