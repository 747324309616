<template>
    <base-form :settings="settings"/>
</template>

<script>
    export default {
        name: 'login-form',

        data() {
            return {
                user : {},
                settings : {

                    title : () => 'Вход',

                    action : {
                        label    : () => 'Войти',
                        href     : '/api/v1/users/login',
                        navigate : '/'
                    },

                    entity : {

                        defaultFocus : (user) => user.email ? 'password' : 'email',

                        fields : {
                            email : {
                                label : () => 'Электронная почта',
                                icon  : 'mdi-account',
                                type  : 'text',
                                trim  : true,
                                validate : {
                                    email     : true,
                                    required  : true,
                                    maxLength : 50
                                }
                            },
                            password : {
                                label : () => 'Пароль',
                                icon  : 'mdi-lock',
                                type  : 'password',
                                validate : {
                                    required  : true,
                                    maxLength : 50
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>