<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card class="login-form elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ settings.title() }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <fude-form
                            ref="form"
                            color="blue"
                            :model="$root.user"
                            :settings="settings.entity"
                            :error="error"
                            @last-field-enter="action"
                    >
                        <slot/>
                    </fude-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            v-for="link in settings.links"
                            outline
                            :key="link.href"
                            :disabled="loading"
                            @click="$navigate(link.href)">
                        <span>{{ link.label() }}</span>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn color="primary" :loading="loading" @click="action()">
                        <span>{{ settings.action.label() }}</span>
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>


</template>

<script>
    export default {
        name: 'base-form',

        props : ['settings'],

        data() {
            return {
                loading : false,
                error   : null
            }
        },

        methods : {
            async action() {
                if (await this.$refs.form.hasErrors() || this.loading) {
                    return;
                }
                this.loading = true;
                this.$http.post(this.settings.action.href, this.$root.user).then((res) => {
                    this.loading = false;
                    this.$root.timeout = res.data.timeout;
                    this.$root.saveLastUser();
                    this.$navigate(this.settings.action.navigate);
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.error = error.response && error.response.data ||
                                 error.message ||
                                 JSON.stringify(error);
                });
            }
        }
    }
</script>

<style lang="less">
    .login-form {
        .v-card__actions {
            padding: 8px 16px 16px;
        }
        .v-alert.error {
            width: 100%;
        }
    }
</style>