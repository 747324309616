import App from '../../app';
import app from './App';
import BaseForm   from './components/base-form';
import LoginForm  from './components/login-form';

App
    .app(app)
    .component(BaseForm)
    .route('/', LoginForm)
    .route('/')
    .mixin({
        data : {
            user: {
                email: '',
                password: ''
            }
        },

        mounted() {
            let email = this.$localStorage.get('last_login');
            if (email) {
                this.user.email = email;
            }
        },

        methods : {
            saveLastUser() {
                this.$localStorage.set('last_login', this.user.email);
            }
        }
    })
    .bootstrap();

